import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Box,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const EditFoodDialogOwnAdded = ({ open, onClose, item, onUpdate, onDelete }) => {
  const [name, setName] = useState('');
  const [kcal, setKcal] = useState('');
  const [mealtime, setMealtime] = useState([]); // Für Multiselect

  // Wenn das Item geändert wird, fülle die Felder mit den Daten des Items
  useEffect(() => {
    if (item) {
      setName(item.product_name || '');
      setKcal(item.kcal_complete || '');
      setMealtime(Array.isArray(item.mealtime) ? item.mealtime : []); // Sicherstellen, dass `mealtime` ein Array ist
    }
  }, [item]);

  const handleUpdate = () => {
    console.log('Werte vor Übergabe:', { id: item.id, name, kcal, mealtime }); // Debugging
    if (!name || !kcal) {
      alert('Bitte alle Felder ausfüllen!');
      return;
    }
    onUpdate(item.id, name, kcal, mealtime); // Werte an die Elternkomponente übergeben
  };

  const handleDelete = () => {
    if (window.confirm('Möchten Sie dieses Lebensmittel wirklich löschen?')) {
      onDelete(item.id); // Löschfunktion ausführen
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lebensmittel bearbeiten</DialogTitle>
      <DialogContent>
        {/* Name */}
        <TextField
          label="Produktname"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* Kalorien */}
        <TextField
          label="Kalorien (Gesamt)"
          fullWidth
          margin="normal"
          type="number"
          value={kcal}
          onChange={(e) => setKcal(e.target.value)}
        />

        {/* Multiselect für Mahlzeiten */}
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel id="mealtime-label">Mahlzeiten</InputLabel>
          <Select
            labelId="mealtime-label"
            value={mealtime}
            onChange={(e) => setMealtime(e.target.value)}
            multiple
            renderValue={(selected) => selected.join(', ')}
            label="Mahlzeiten"
          >
            <MenuItem value="breakfast">Frühstück</MenuItem>
            <MenuItem value="lunch">Mittagessen</MenuItem>
            <MenuItem value="dinner">Abendessen</MenuItem>
            <MenuItem value="snacks">Snacks</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          {/* Icon-Button für Löschen */}
          <IconButton
            onClick={handleDelete}
            color="error"
            sx={{
              border: '1px solid', // Optional: Rahmen für Konsistenz mit anderen Buttons
              borderColor: 'error.main',
              padding: '8px',
            }}
          >
            <Delete />
          </IconButton>
          {/* Abbrechen- und Speichern-Buttons rechts */}
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Abbrechen
            </Button>
            <Button onClick={handleUpdate} color="primary" variant="contained">
              Speichern
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditFoodDialogOwnAdded;
