import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

const Settings = () => {
    const [dialogOpen, setDialogOpen] = useState(null); // null = geschlossen, 'privacy' oder 'impressum' = offen

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = 'https://nobnohungry.de/dashboard';
    };

    const dialogContent = {
        privacy: (
            <>
                <DialogTitle>Datenschutzerklärung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Diese Anwendung speichert personenbezogene Daten wie Benutzer-ID, Produktnamen und Kalorienangaben, um den Service bereitzustellen.
                        Alle Daten werden nach geltendem Datenschutzrecht verarbeitet und nicht an Dritte weitergegeben. Sie können jederzeit die Löschung Ihrer Daten beantragen.
                    </Typography>
                </DialogContent>
            </>
        ),
        impressum: (
            <>
                <DialogTitle>Impressum</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>Angaben gemäß § 5 TMG</Typography>
                    <Typography>Daniel Hörenkamp</Typography>
                    <Typography>Kreuzstraße 69</Typography>
                    <Typography>44575 Castrop-Rauxel</Typography>
                    <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>Kontakt:</Typography>
                    <Typography>E-Mail: d.hoerenkamp@gmail.com</Typography>
                    <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</Typography>
                    <Typography>Daniel Hörenkamp</Typography>
                    <Typography>Kreuzstraße 69</Typography>
                    <Typography>44575 Castrop-Rauxel</Typography>
                </DialogContent>
            </>
        ),
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Erweiterte Einstellungen
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleLogout}
                    sx={{ marginBottom: 2 }}
                >
                    Logout
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button
                        variant="outlined"
                        onClick={() => setDialogOpen('privacy')}
                    >
                        Datenschutzerklärung
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setDialogOpen('impressum')}
                    >
                        Impressum
                    </Button>
                </Box>
            </Box>
            <Dialog open={dialogOpen !== null} onClose={() => setDialogOpen(null)}>
                {dialogOpen && dialogContent[dialogOpen]}
                <DialogActions>
                    <Button onClick={() => setDialogOpen(null)} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Settings;
