import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from '@mui/material';

const FoodDialogOwnAdded = ({
  open,
  onClose,
  item,
  quantity,
  setQuantity,
  onAdd,
}) => {
  if (!item) return null; // Schutz gegen fehlende Daten
  const calculatedCalories = item.kcal_complete * quantity;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lebensmittel hinzufügen</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Produkt: <strong>{item.product_name}</strong>
        </Typography>
        <Typography gutterBottom>
          Kalorien (Gesamt): <strong>{item.kcal_complete} kcal</strong>
        </Typography>
        <TextField
          label="Menge (Portionen)"
          type="tel"
          fullWidth
          value={quantity === '' ? '' : quantity}
          onChange={(e) => {
            const value = e.target.value;
            setQuantity(value === '' ? '' : Number(value));
          }}
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={() => onAdd(calculatedCalories)}
          color="primary"
          variant="contained"
          disabled={!quantity || quantity <= 0} // Button deaktiviert, wenn Menge ungültig ist
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoodDialogOwnAdded;