import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Korrektur des Imports

const AuthChecker = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const publicRoutes = ['/login', '/register'];

  const checkTokenValidity = () => {
    const token = localStorage.getItem('token');

    if (publicRoutes.includes(location.pathname)) {
      return;
    }

    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    } catch (error) {
      console.error('Ungültiger Token:', error);
      localStorage.removeItem('token');
      navigate('/login');
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, [location.pathname]);

  return <>{children}</>;
};

export default AuthChecker;