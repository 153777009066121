import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

const AddFoodDialog = ({ open, onClose, onFoodAdded }) => {
  const [productName, setProductName] = useState('');
  const [kcal, setKcal] = useState('');
  const [meals, setMeals] = useState([]);

  const handleAddFood = () => {
    if (productName && kcal && meals.length > 0) {
      const token = localStorage.getItem('token');
      if (!token) return;

      const currentDate = new Date().toISOString();

      fetch('/api/food/add-own-food', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          product_name: productName,
          kcal_complete: parseInt(kcal, 10),
          mealtime: meals,
          updated_at: currentDate,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Food added:', data);
          setProductName('');
          setKcal('');
          setMeals([]);
          onFoodAdded(); // Callback, um Elternkomponente zu informieren
          onClose();
        })
        .catch((error) => console.error('Error adding food:', error));
    } else {
      alert('Bitte alle Felder ausfüllen und mindestens eine Mahlzeit auswählen.');
    }
  };

  const isAddButtonDisabled = !productName || !kcal || parseInt(kcal, 10) <= 0 || meals.length === 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Neues Lebensmittel hinzufügen</DialogTitle>
      <DialogContent>
        <TextField
          label="Produktname"
          fullWidth
          margin="normal"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
        <TextField
          label="Kalorien (Gesamt)"
          fullWidth
          margin="normal"
          type="number"
          value={kcal}
          onChange={(e) => setKcal(e.target.value)}
        />
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel id="mealtime-label">Mahlzeiten</InputLabel>
          <Select
            labelId="mealtime-label"
            value={meals}
            onChange={(e) => setMeals(e.target.value)}
            multiple
            renderValue={(selected) => selected.join(', ')}
            label="Mahlzeiten" // Label wird in die Box integriert
          >
            <MenuItem value="breakfast">Frühstück</MenuItem>
            <MenuItem value="lunch">Mittagessen</MenuItem>
            <MenuItem value="dinner">Abendessen</MenuItem>
            <MenuItem value="snacks">Snacks</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={handleAddFood}
          color="primary"
          variant="contained"
          disabled={isAddButtonDisabled} // Button wird deaktiviert, wenn Bedingungen nicht erfüllt sind
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFoodDialog;
