import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, IconButton, Card, CardContent, Fab, Menu, MenuItem, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Add, ArrowBack, ArrowForward, Restaurant, LocalCafe, Fastfood, LocalPizza } from '@mui/icons-material';
import { Doughnut, Line } from 'react-chartjs-2';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement );

const Dashboard = () => {
  const navigate = useNavigate();
  const [totalCalories, setTotalCalories] = useState(null);
  const [consumedCalories, setConsumedCalories] = useState(0);
  const [caloriesByMealtime, setCaloriesByMealtime] = useState({
    breakfast: 0,
    lunch: 0,
    dinner: 0,
    snacks: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [consumedFood, setConsumedFood] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [caloriesData, setCaloriesData] = useState([]);

  const formatDateForDB = (date) => date.toISOString().split('T')[0];
  const formatDateForDisplay = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
  const [menuOpen, setMenuOpen] = React.useState(false);
  const formattedDate = currentDate.toLocaleDateString('de-DE');
  const handleDelete = (id) => {
    if (window.confirm('Möchten Sie dieses Lebensmittel wirklich löschen?')) {
      deleteConsumedFood(id) // Löschfunktion ausführen
    }
  };

  const fetchCalories = async (date) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('/api/users/get-calories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Token im Authorization-Header
        },
        body: JSON.stringify({ date }),
      });

      if (response.ok) {
        const data = await response.json();
        setConsumedCalories(data.total_calories);
      } else {
        console.error('Fehler beim Laden der Kalorien.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // API-Aufruf, um konsumierte Lebensmittel für das aktuelle Datum abzurufen
  const fetchConsumedFood = async (date) => {

    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('/api/users/get-consumed-calories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ date }),
      });

      if (!response.ok) {
        throw new Error('Fehler beim Abrufen der konsumierten Lebensmittel');
      }
      const data = await response.json();
      setConsumedFood(data);
    } catch (error) {
      console.error(error);
    }
  };

  // API-Aufruf, um einen Eintrag zu löschen
  const deleteConsumedFood = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
      return;
    }

    try {
      const response = await fetch(`/api/users/delete-consumed-calories?id=${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Fehler beim Löschen des Lebensmittels');
      }
      // Erfolgreich gelöscht, Liste aktualisieren
      setConsumedFood((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCaloriesByMealtime = async (date) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('/api/users/get-calories-by-mealtime', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ date }),
      });

      if (response.ok) {
        const data = await response.json();
        setCaloriesByMealtime(data);
      } else {
        console.error('Fehler beim Laden der Kalorien nach Mahlzeit.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    }
  };

  const fetchUserSettings = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
      return;
    }

    try {
      const response = await fetch('/api/users/get-settings', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTotalCalories(data.calories);
      } else {
        console.error('Fehler beim Laden der Benutzereinstellungen.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    }
  };

  const fetchCaloriesLastSevenDays = async (endDate) => {
    const token = localStorage.getItem('token');
    if (!token) return [];
  
    try {
      const response = await fetch('/api/users/get-calories-last-seven-days', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ endDate }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return data; // Array mit [{ date, consumedCalories }, ...]
      } else {
        console.error('Fehler beim Laden der 7-Tage-Daten.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    }
  
    return [];
  };

  useEffect(() => {
    fetchUserSettings();
    fetchCalories(currentDate);
    fetchCaloriesByMealtime(currentDate);
    fetchConsumedFood(currentDate);
  }, [currentDate]);

  const remainingCalories = totalCalories ? totalCalories - consumedCalories : 0;
  const isOverLimit = consumedCalories >= (totalCalories || 0);

  const data = {
    labels: ['Konsumiert', 'Übrig'],
    datasets: [
      {
        label: 'Kalorien',
        data: [consumedCalories, remainingCalories > 0 ? remainingCalories : 0],
        backgroundColor: isOverLimit ? ['#FF0000', '#FF0000'] : ['#FFA500', '#008000'],
        hoverBackgroundColor: isOverLimit ? ['#FF0000', '#FF0000'] : ['#FFA500', '#008000'],
        borderWidth: 1,
        cutout: '50%',
        rotation: -90,
        circumference: 180,
      },
    ],
  };

  /* 1 - Funktionen zum Abrufen der Daten für das letzte 7 Tage Diagramm*/
  const processCaloriesData = (data) => {
    if (!Array.isArray(data)) {
      console.error('Ungültiges Datenformat:', data);
      return [];
    }
  
    return data.map(entry => ({
      date: entry.date, // Datum bleibt ein String für Labels
      consumedCalories: Number(entry.consumedcalories), // Konvertiere in Zahl
    })).sort((a, b) => new Date(a.date) - new Date(b.date)); // Sortiere nach Datum
  };
 

  useEffect(() => {
    const loadLastSevenDays = async () => {
      const rawData = await fetchCaloriesLastSevenDays(formatDateForDB(currentDate));
      const processedData = processCaloriesData(rawData);  
      setCaloriesData(processedData);
    };
  
    loadLastSevenDays();
  }, [currentDate]);

  const lineChartData = () => {
    console.log('caloriesData:', caloriesData);
    console.log('totalCalories:', totalCalories);
    if (!caloriesData.length || totalCalories === null) {
      console.error('Fehlende oder unvollständige Daten:', caloriesData, totalCalories);
      return null;
    }
  
    const labels = caloriesData.map(entry => entry.date); // X-Achse: Datum
    const consumed = caloriesData.map(entry => entry.consumedCalories); // Y-Achse: Kalorien
  
    const pointColors = consumed.map(value => 
      value > totalCalories 
        ? '#FF0000' // Rot: über Ziel-Kalorien
        : value === totalCalories 
          ? '#FFA500' // Orange: genau auf der Linie
          : '#008000' // Grün: unter Ziel-Kalorien
    );
  
    return {
      labels,
      datasets: [
        {
          label: 'Konsumierte Kalorien',
          data: consumed,
          borderColor: '#FFA500',
          backgroundColor: 'rgba(255, 165, 0, 0.2)',
          fill: true,
          tension: 0.4,
          pointBackgroundColor: pointColors, // Punktfarben basierend auf den Werten
        },
        {
          label: 'Ziel-Kalorien',
          data: Array(consumed.length).fill(totalCalories),
          borderColor: '#008000',
          borderDash: [5, 5],
          backgroundColor: 'transparent',
        },
      ],
    };
  };
  
  /* 1 - ENDE */

  const handlePreviousDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Box textAlign="center" my={2}>
        <Typography variant="h4">Dashboard</Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
        <IconButton onClick={handlePreviousDay}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">{formatDateForDisplay(currentDate)}</Typography>
        <IconButton onClick={handleNextDay}>
          <ArrowForward />
        </IconButton>
      </Box>

      {isLoading ? (
        <Typography>Lade Daten...</Typography>
      ) : (
        <>
          <Card sx={{ mb: 2 }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box textAlign="center">
                <Typography variant="h6">Eingetragen</Typography>
                <Typography>{consumedCalories} kcal</Typography>
              </Box>

              <Box width="40%">
                <Doughnut data={data} options={{ responsive: true, maintainAspectRatio: false }} />
              </Box>

              <Box textAlign="center">
                <Typography variant="h6">Übrige Kalorien</Typography>
                <Typography>
                  {totalCalories !== null ? `${totalCalories - consumedCalories} kcal` : 'Lade...'}
                </Typography>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>Kalorien nach Mahlzeiten</Typography>
              <ul
                style={{
                  listStyleType: 'none', // Entfernt die Aufzählungspunkte
                  padding: 0, // Entfernt den Standard-Innenabstand
                  margin: 0, // Entfernt den Standard-Außenabstand
                }}
              >
                <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
                    Frühstück
                  </div>
                  <div style={{ width: '50%', textAlign: 'left' }}>
                    {caloriesByMealtime.breakfast} kcal
                  </div>
                </li>
                <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
                    Mittagessen
                  </div>
                  <div style={{ width: '50%', textAlign: 'left' }}>
                    {caloriesByMealtime.lunch} kcal
                  </div>
                </li>
                <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
                    Abendessen
                  </div>
                  <div style={{ width: '50%', textAlign: 'left' }}>
                    {caloriesByMealtime.dinner} kcal
                  </div>
                </li>
                <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <div style={{ width: '50%', textAlign: 'right', paddingRight: '16px' }}>
                    Snacks
                  </div>
                  <div style={{ width: '50%', textAlign: 'left' }}>
                    {caloriesByMealtime.snacks} kcal
                  </div>
                </li>
              </ul>
            </CardContent>
          </Card>

          {lineChartData() && (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Kalorienziele der letzten 7 Tage
      </Typography>
      <Box height={300}>
  <Line
    data={lineChartData()}
    options={{
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: Math.ceil(
            (Math.max(...caloriesData.map(entry => entry.consumedCalories), totalCalories) + 500) / 100
          ) * 100,
        },
      },
    }}
  />
</Box>
    </CardContent>
  </Card>
)}

      {/* Tabelle für konsumierte Lebensmittel */}
     
      <Card sx={{ mt: 2, mb: 12 }}>
  <CardContent sx={{ maxHeight: 300, overflow: 'auto' }}>
    <Typography variant="h6">Konsumierte Lebensmittel ({formattedDate})</Typography>
    <Table>
      <TableBody>
        {consumedFood.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan={2}>
              Keine Einträge gefunden.
            </TableCell>
          </TableRow>
        ) : (
          consumedFood.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <Typography variant="body1">
                  {item.name} - {item.kcal} kcal
                </Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="error"
                  onClick={() => handleDelete(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </CardContent>
</Card>

          {/* Floating Action Button */}
          {menuOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backdropFilter: 'blur(8px)', // Unscharfer Hintergrund
              backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dunkler, halbtransparenter Hintergrund
              zIndex: 5, // Unter dem Menü und FAB, aber über dem restlichen Inhalt
            }}
            onClick={handleCloseMenu} // Schließt das Menü, wenn der Hintergrund angeklickt wird
          />
        )}
          
          <Fab
          color="primary"
          aria-label="add"
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 16,
            zIndex: 10, // Über dem unscharfen Hintergrund
          }}
        >
          <Add />
        </Fab>

          {/* Menü über dem FAB */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top', // Menü startet oberhalb des FAB
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom', // Menü schwebt über dem FAB
              horizontal: 'center',
            }}
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: 'transparent', // Hintergrund des Menüs transparent machen
                boxShadow: 'none', // Schatten entfernen
                padding: 0, // Entfernt unnötige Abstände
                zIndex: 10, // Über dem unscharfen Hintergrund
              },
            }}
          >
            <MenuItem
              onClick={() => { navigate(`/breakfast?date=${formatDateForDB(currentDate)}`); handleCloseMenu(); }}
              sx={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FFFFFF',
                borderRadius: '16px',
                margin: '8px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              <LocalCafe sx={{ mr: 1 }} /> Frühstück
            </MenuItem>
            <MenuItem
              onClick={() => { navigate(`/lunch?date=${formatDateForDB(currentDate)}`); handleCloseMenu(); }}
              sx={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FFFFFF',
                borderRadius: '16px',
                margin: '8px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              <Restaurant sx={{ mr: 1 }} /> Mittagessen
            </MenuItem>
            <MenuItem
              onClick={() => { navigate(`/dinner?date=${formatDateForDB(currentDate)}`); handleCloseMenu(); }}
              sx={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FFFFFF',
                borderRadius: '16px',
                margin: '8px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              <LocalPizza sx={{ mr: 1 }} /> Abendessen
            </MenuItem>
            <MenuItem
              onClick={() => { navigate(`/snacks?date=${formatDateForDB(currentDate)}`); handleCloseMenu(); }}
              sx={{
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FFFFFF',
                borderRadius: '16px',
                margin: '8px',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              <Fastfood sx={{ mr: 1 }} /> Snacks
            </MenuItem>
          </Menu>

        </>
      )}
    </Container>
  );
};

export default Dashboard;