import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Home, Person, Settings } from '@mui/icons-material';
import AuthChecker from './Components/AuthChecker';
import Login from './Login/Login';
import Register from './Login/Register';
import Profile from './Settings/Profile';
import Usersettings from './Settings/Settings';
import Dashboard from './Dashboard/Dashboard';
import BreakfastPage from './Foodtracking/BreakfastPage';
import LunchPage from './Foodtracking/LunchPage';
import DinnerPage from './Foodtracking/DinnerPage';
import SnacksPage from './Foodtracking/SnacksPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
      <AuthChecker>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/settings" element={<Usersettings/>} />
          <Route path="/profile" element={<Profile />} />
          {/* Hier binden wir die Dashboard-Komponente ein */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/breakfast" element={<BreakfastPage />} />
          <Route path="/lunch" element={<LunchPage />} />
          <Route path="/dinner" element={<DinnerPage />} />
          <Route path="/snacks" element={<SnacksPage />} />
        </Routes>
        </AuthChecker>
        <BottomNav />
      </div>
    </Router>
  );
}

// Untere Navigationsleiste
const BottomNav = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate('/dashboard');
    if (newValue === 1) navigate('/profile');
    if (newValue === 2) navigate('/settings');
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 16, left: 16, right: 16, borderRadius: '32px'}} elevation={3}>
      <BottomNavigation sx={{ backgroundColor: 'transparent' }} value={value} onChange={handleNavigationChange}>
        <BottomNavigationAction label="Dashboard" icon={<Home />} />
        <BottomNavigationAction label="Profil" icon={<Person />} />
        <BottomNavigationAction label="Einstellungen" icon={<Settings />} />
      </BottomNavigation>
    </Paper>
  );
};

export default App;