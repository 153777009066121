import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate statt useHistory
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  AppBar,
  Toolbar,
} from '@mui/material';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate(); // useNavigate-Hook

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch('https://nobnohungry.de/api/verify-token', {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.valid) {
            navigate('/dashboard'); // navigate statt history.push
          }
        })
        .catch((err) => console.error('Token verification failed', err));
    }
  }, [navigate]);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      return;
    }

    try {
      const response = await fetch('https://nobnohungry.de/api/users/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('user_id', data.id);
        navigate('/profile'); // navigate statt history.push
      } else {
        console.log('Registration failed');
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Register</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <form onSubmit={handleRegister}>
          <Typography variant="h5" gutterBottom>
            Create your account
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Box mt={2}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
            >
              Register
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default Register;