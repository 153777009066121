import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { ArrowBack, Search, CameraAlt, Close, Edit } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useNavigate, useLocation } from 'react-router-dom';
import Quagga from 'quagga';
import FoodDialog from '../Components/FoodDialog';
import FoodDialogOwnAdded from '../Components/FoodDialogOwnAdded';
import EditFoodDialogOwnAdded from '../Components/EditFoodDialogOwnAdded';
import AddFoodDialog from '../Components/AddFoodDialog';

const LunchPage = () => {
  const [searchText, setSearchText] = useState('');
  const [foodItems, setFoodItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [unit, setUnit] = useState('100');
  const [totalCalories, setTotalCalories] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isOwnDialogOpen, setOwnDialogOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [lastAddedItems, setLastAddedItems] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [ownAddedItems, setOwnAddedItems] = useState([]);
  const [isFromSearch, setIsFromSearch] = useState(false);
  const [filteredLastAddedItems, setFilteredLastAddedItems] = useState([]);
  const [filteredOwnAddedItems, setFilteredOwnAddedItems] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const navigate = useNavigate();
  const mealtime = 'lunch';

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // GET-Parameter aus der URL
  const dateFromParams = queryParams.get('date'); // Den 'date'-Parameter holen

  const [selectedDate, setSelectedDate] = useState(
    dateFromParams || new Date().toISOString().split('T')[0] // Fallback auf heutiges Datum
  );

  useEffect(() => {
    if (selectedItem) {
      const baseKcal = selectedItem.kcal || selectedItem.kcal_per_100g; // Je nach Datenstruktur
      const baseUnit = 100;

      // Berechnung abhängig von der Einheit
      const calculatedCalories =
        unit === 'g' || unit === 'ml'
          ? (baseKcal / baseUnit) * quantity // Gramm oder ml: erst durch 100 teilen, dann multiplizieren
          : unit === 'portion'
            ? selectedItem.kcal_serving * quantity
            : baseKcal * quantity; // 100-Gramm-Basis oder andere Multiplikation

      setTotalCalories(Math.round(calculatedCalories));
    }
  }, [quantity, unit, selectedItem]);

  useEffect(() => {
    if (isScanning) {
      const videoTarget = document.querySelector('#video-container');
      if (!videoTarget) {
        console.error('Video-Container nicht gefunden.');
        setSnackbarMessage('Kamera konnte nicht gestartet werden.');
        setIsScanning(false);
        return;
      }

      Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            target: videoTarget,
            constraints: {
              facingMode: 'environment',
            },
          },
          decoder: {
            readers: [
              'ean_reader',        // EAN-13
              'ean_8_reader',      // EAN-8
            ],
          },
          locate: true,  // Barcode-Lokalisierung aktivieren
          debug: true,   // Debugging aktivieren
          locator: {
            patchSize: 'medium',
            halfSample: true,
          },
        },
        (err) => {
          if (err) {
            console.error('Fehler bei der Initialisierung von Quagga:', err);
            setSnackbarMessage('Kamera konnte nicht gestartet werden.');
            setIsScanning(false);
            return;
          }

          console.log('Quagga erfolgreich initialisiert. Starte Kamera...');
          try {
            Quagga.start();
          } catch (error) {
            console.error('Fehler beim Starten von Quagga:', error);
            setSnackbarMessage('Fehler beim Starten der Kamera.');
            setIsScanning(false);
          }
        }
      );

      Quagga.onDetected((data) => {
        if (data?.codeResult?.code) {
          const barcode = data.codeResult.code;
          console.log('Barcode erkannt:', barcode);
          setSearchText(barcode);
          stopScanning();
        }
      });

      return () => {
        try {
          console.log('Stoppe Quagga...');
          Quagga.stop();
        } catch (error) {
          console.error('Fehler beim Stoppen von Quagga:', error);
        }
      };
    }
  }, [isScanning]);

  useEffect(() => {
    setFilteredLastAddedItems(lastAddedItems);
    setFilteredOwnAddedItems(ownAddedItems);
  }, [lastAddedItems, ownAddedItems]);

  // Funktion zum Filtern der Tabellen
  const handleFilter = (query) => {
    setSearchText(query);

    if (tabIndex === 0) {
      setFilteredLastAddedItems(
        lastAddedItems.filter((item) =>
          item.product_name.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else if (tabIndex === 1) {
      setFilteredOwnAddedItems(
        ownAddedItems.filter((item) =>
          item.product_name.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };

  //funktion zum Abrufen des Produkts per API
  const handleSearch = async (query) => {
    setIsSearching(true);
    setFoodItems([]);
    if (/^\d+$/.test(query)) {
      try {
        const response = await fetch(
          `https://world.openfoodfacts.org/api/v0/product/${query}.json`
        );
        const data = await response.json();
        if (data.product) {
          setFoodItems([
            {
              product_name: data.product.product_name,
              kcal: Math.round(data.product.nutriments['energy-kcal_100g']),
              kcal_serving: Math.round(data.product.nutriments['energy-kcal_serving']),
              unit: data.product.product_quantity_unit === 'ml' ? 'ml' : 'g',
            },
          ]);
          setIsFromSearch(true);
        } else {
          setSnackbarMessage('Produkt nicht gefunden');
        }
      } catch (error) {
        setSnackbarMessage('Fehler bei der Produktsuche');
      }
    } else {
      window.open(
        `https://world.openfoodfacts.org/cgi/search.pl?search_terms=${query}&search_simple=1&action=process`
      );
    }
    setIsSearching(false);
  };

  const handleAddFood = async (calculatedCalories, isOwnFood = false) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
        setDialogOpen(false);
        return;
      }

      const payload = {
        name: selectedItem?.product_name || '', // Name des Produkts
        mealtime: 'lunch', // Feste Mahlzeit setzen
        kcal: calculatedCalories || Math.round(totalCalories), // Gerundete Kalorien
        date: selectedDate, // Datum aus dem State
        isOwnFood, // Übergabe, ob es ein eigenes Lebensmittel ist
      };

      const response = await fetch('/api/users/add-food-consumed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Token im Header senden
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSnackbarMessage('Produkt erfolgreich hinzugefügt!');
      } else {
        setSnackbarMessage('Fehler beim Hinzufügen des Produkts.');
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Ein unerwarteter Fehler ist aufgetreten.');
    }
  };

  const startScanning = () => {
    if (isScanning) return;
    setIsScanning(true);
  };

  const stopScanning = () => {
    setIsScanning(false);
  };

  const handleLastAddedFood = async (foodData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
        setDialogOpen(false);
        return;
      }

      const payload = { ...foodData, date: selectedDate };

      const response = await fetch('/api/food/add-last-added-food', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Token im Header senden
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSnackbarMessage('Produkt erfolgreich hinzugefügt!');
      } else {
        setSnackbarMessage('Fehler beim Hinzufügen des Produkts.');
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Ein unerwarteter Fehler ist aufgetreten.');
    }

    setDialogOpen(false); // Dialog schließen
  };

  useEffect(() => {
    const fetchLastAddedItems = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Kein Token vorhanden');
          return;
        }

        const response = await fetch('/api/food/last-added', {
          method: 'POST', // Geändert von GET auf POST
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ mealtime }), // Übergibt die mealtime im Body
        });

        if (response.ok) {
          const data = await response.json();
          setLastAddedItems(data);
        } else {
          console.error('Fehler beim Laden der Daten:', await response.text());
        }
      } catch (error) {
        console.error('Netzwerkfehler:', error.message);
      }
    };

    fetchLastAddedItems();
  }, []);

  // Funktion zum Abrufen der selbst hinzugefügten Lebensmittel
  const fetchOwnAddedItems = async () => {
    try {
      const token = localStorage.getItem('token');
      const mealtime = 'lunch';

      if (!token) {
        console.error('Kein Token vorhanden');
        return;
      }

      const response = await fetch(`/api/food/own-added?mealtime=${encodeURIComponent(mealtime)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setOwnAddedItems(data); // Aktualisiere den State mit den abgerufenen Daten
      } else {
        console.error('Fehler beim Laden der Daten:', await response.text());
      }
    } catch (error) {
      console.error('Netzwerkfehler:', error.message);
    }
  };

  // zum laden der Lebensmittel beim direkten Aufruf der Seite
  useEffect(() => {
    fetchOwnAddedItems(); // Rufe die Funktion beim Laden der Seite auf
  }, []);

  const handleUpdateFood = async (id, newName, newKcal, newMealtime) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
        return;
      }

      const response = await fetch('/api/food/update-own-food', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id,
          product_name: newName,
          kcal_complete: newKcal,
          mealtime: newMealtime,
        }),
      });

      if (response.ok) {
        setSnackbarMessage('Lebensmittel erfolgreich aktualisiert!');
        fetchOwnAddedItems(); // Liste neu laden
        setEditDialogOpen(false); // Dialog schließen
      } else {
        console.error('Fehler beim Aktualisieren:', await response.text());
      }
    } catch (error) {
      console.error('Netzwerkfehler:', error.message);
    }
  };

  const handleDeleteFood = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setSnackbarMessage('Fehler: Nutzer nicht eingeloggt');
        return;
      }

      const response = await fetch(`/api/food/delete-own-food?id=${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setSnackbarMessage('Lebensmittel erfolgreich gelöscht!');
        fetchOwnAddedItems(); // Liste neu laden
        setEditDialogOpen(false); // Dialog schließen
      } else {
        console.error('Fehler beim Löschen:', await response.text());
      }
    } catch (error) {
      console.error('Netzwerkfehler:', error.message);
    }
  };

  return (
    <Container maxWidth="md">
      {/* Header */}
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          onClick={() => navigate('/dashboard')}
          aria-label="back"
          size="large"
        >
          <ArrowBack />
        </IconButton>

        <Typography
          variant="h4"
          align="center"
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, // Dynamische Schriftgröße
          }}
        >
          Mittagessen hinzufügen
        </Typography>

        <Box sx={{ width: 48 }} /> {/* Platzhalter rechts */}
      </Box>

      {/* Suchleiste */}
      <Box mt={2} display="flex" alignItems="center">
        <TextField
          fullWidth
          placeholder="Artikel suchen"
          value={searchText}
          onChange={(e) => handleFilter(e.target.value)}
        />
        <IconButton onClick={() => handleSearch(searchText)} aria-label="search">
          <Search />
        </IconButton>
        <IconButton onClick={startScanning} aria-label="camera">
          <CameraAlt />
        </IconButton>
      </Box>

      {/* Kamera Overlay */}
      {isScanning && (
        <Box
          position="fixed"
          top="10%"
          left="10%"
          width="80%"
          height="70%"
          bgcolor="rgba(0, 0, 0, 0.8)"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1300}
          borderRadius={4}
        >
          <Box
            id="video-container"
            width="100%"
            height="100%"
            borderRadius={4}
            overflow="hidden"
            position="relative"
            backgroundColor="black"
          />
          <IconButton
            onClick={stopScanning}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}

      {/* Gefundenes Produkt oder Platzhalter */}
      <Box mt={2}>
        <Card>
          <CardContent>
            {isSearching ? (
              <Typography textAlign="center">
                <CircularProgress size={24} sx={{ mr: 1 }} />
                Produkt wird gesucht...
              </Typography>
            ) : foodItems.length > 0 ? (
              <>
                <Typography>
                  {foodItems[0].product_name} - ({foodItems[0].kcal} kcal / 100
                  {foodItems[0].unit})
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedItem(foodItems[0]); // Setzt das ausgewählte Produkt
                    setQuantity(1); // Standardwert setzen
                    setUnit('100'); // Standardwert setzen
                    setDialogOpen(true); // Dialog öffnen
                  }}
                  sx={{ mt: 2 }}
                >
                  Hinzufügen
                </Button>
              </>
            ) : (
              <Typography textAlign="center" color="text.secondary">
                Für die Suche nach einem Produkt die EAN - Nummer eingeben
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      {/* Tabs und Tabelle */}
      <Box mt={2}>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)} centered   
        variant="scrollable" // Tabs können horizontal scrollen
        scrollButtons="auto" // Zeigt Pfeile zum Scrollen bei Bedarf an
        sx={{
        maxWidth: '100%', // Tabs nehmen den gesamten verfügbaren Platz ein
        overflowX: 'auto', // Horizontaler Overflow für kleine Bildschirme
        }}
        >
          <Tab label="Letzte Ergebnisse" />
          <Tab label="Eigene Kombinationen" />
        </Tabs>
        <Box mt={2} sx={{ position: "relative" }}>
          {tabIndex === 0 && (
            <Table
            sx={{
              width: '100%',
              tableLayout: 'fixed', // Verhindert, dass die Tabelle die Breite basierend auf dem Inhalt anpasst
            }}
            >
              <TableBody
                  sx={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
              >
                {filteredLastAddedItems.length === 0 ? (
                  <TableRow
                    sx={{
                      display: 'flex',          // Flexbox für die Zeile
                      width: '100%',            // Zeile nimmt die gesamte Breite des TableBody ein
                      justifyContent: 'center', // Inhalt zentrieren
                      alignItems: 'center',     // Vertikale Ausrichtung
                    }}
                  >
                    <TableCell
                      sx={{
                        flex: 1,                // Zelle nimmt die gesamte Zeile ein
                        borderBottom: 'none',   // Optional: Entferne Tabellenlinien
                        textAlign: 'center',    // Text zentrieren
                        padding: '16px',        // Optional: Platz für mehr Lesbarkeit
                      }}
                    >
                      <Typography textAlign="center" variant="body1">
                        Keine Einträge gefunden.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredLastAddedItems.map((item, index) => (
                    <TableRow
                        key={index}
                        sx={{
                          display: 'flex', // Flexbox für die Zeile
                          width: '100%', // Zeile nimmt die gesamte Breite des TableBody ein
                          maxWidth: '100%', // Zeile soll nicht größer als die Tabelle werden
                          alignItems: 'center',
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                      {/* Titel-Zelle */}
                      <TableCell
                        sx={{
                          flex: 2,
                          whiteSpace: 'normal', // Erlaubt Zeilenumbruch
                          wordWrap: 'break-word', // Bricht Wörter um, wenn sie zu lang für die Zelle sind
                          overflow: 'hidden', // Verhindert, dass Text über die Zelle hinausragt
                          borderBottom: 'none', // Entferne Tabellenlinien (optional)
                          textOverflow: 'ellipsis', // Optional: Zeigt "..." für abgeschnittenen Text
                        }}
                      >
                        {item.product_name} - ({item.kcal_per_100g} kcal / 100{item.unit})
                      </TableCell>

                      {/* Button-Zelle */}
                      <TableCell
                        sx={{
                          flex: 1, // Buttons nehmen 1 Teil der Breite ein
                          display: 'flex', // Flexbox für die Buttons
                          justifyContent: 'flex-end', // Buttons rechts ausrichten
                          borderBottom: 'none', // Entferne Tabellenlinien (optional)
                          gap: 1, // Abstand zwischen den Buttons
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setSelectedItem(item);
                            setQuantity(1);
                            setUnit('100');
                            setDialogOpen(true);
                          }}
                        >
                          Erfassen
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          {tabIndex === 1 && (
            <>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAddDialogOpen(true)}
                >
                  Neues Lebensmittel hinzufügen
                </Button>
              </Box>
              <Table
              sx={{
                width: '100%',
                tableLayout: 'fixed', // Verhindert, dass die Tabelle die Breite basierend auf dem Inhalt anpasst
              }}
              >
                <TableBody
                  sx={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {filteredOwnAddedItems.length === 0 ? (
                    <TableRow
                      sx={{
                        display: 'flex',          // Flexbox für die Zeile
                        width: '100%',            // Zeile nimmt die gesamte Breite des TableBody ein
                        justifyContent: 'center', // Inhalt zentrieren
                        alignItems: 'center',     // Vertikale Ausrichtung
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableCell
                        sx={{
                          flex: 1,                // Zelle nimmt die gesamte Zeile ein
                          borderBottom: 'none',   // Optional: Entferne Tabellenlinien
                          textAlign: 'center',    // Text zentrieren
                          padding: '16px',        // Optional: Platz für mehr Lesbarkeit
                          borderBottom: 'none',   // Entferne Tabellenlinien (optional)
                        }}
                      >
                        <Typography textAlign="center" variant="body1">
                          Keine Einträge gefunden.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredOwnAddedItems.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          display: 'flex', // Flexbox für die Zeile
                          width: '100%', // Zeile nimmt die gesamte Breite des TableBody ein
                          maxWidth: '100%', // Zeile soll nicht größer als die Tabelle werden
                          alignItems: 'center',
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        {/* Titel-Zelle */}
                        <TableCell
                          sx={{
                            flex: 2,
                            whiteSpace: 'normal', // Erlaubt Zeilenumbruch
                            wordWrap: 'break-word', // Bricht Wörter um, wenn sie zu lang für die Zelle sind
                            overflow: 'hidden', // Verhindert, dass Text über die Zelle hinausragt
                            borderBottom: 'none', // Entferne Tabellenlinien (optional)
                            textOverflow: 'ellipsis', // Optional: Zeigt "..." für abgeschnittenen Text
                          }}
                        >
                          <Typography variant="body1">
                            {item.product_name} - ({item.kcal_complete} kcal)
                          </Typography>
                        </TableCell>

                        {/* Buttons-Zelle */}
                        <TableCell
                          sx={{
                            flex: 1, // Buttons nehmen 1 Teil der Breite ein
                            display: 'flex', // Flexbox für die Buttons
                            justifyContent: 'flex-end', // Buttons rechts ausrichten
                            borderBottom: 'none', // Entferne Tabellenlinien (optional)
                            gap: 1, // Abstand zwischen den Buttons
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              setSelectedItem(item);
                              setEditDialogOpen(true);
                            }}
                            sx={{
                              minWidth: 'auto',
                              padding: '6px',
                            }}
                          >
                            <Edit fontSize="small" />
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxIcon />}
                            onClick={() => {
                              setSelectedItem(item);
                              setQuantity(1);
                              setOwnDialogOpen(true);
                            }}
                            sx={{
                              minWidth: 'auto',
                              px: 1,
                            }}
                          >
                            Erfassen
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
      </Box>

      {/* Dialog für "Letzte Ergebnisse" */}
      <FoodDialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        item={selectedItem}
        quantity={quantity}
        setQuantity={setQuantity}
        unit={unit}
        setUnit={setUnit}
        totalCalories={totalCalories}
        onAdd={() => {
          if (selectedItem) {
            if (isFromSearch) {
              handleLastAddedFood({
                product_name: selectedItem.product_name,
                kcal_per_100g: selectedItem.kcal,
                unit: selectedItem.unit,
                mealtime: 'lunch',
                kcal_serving: selectedItem.kcal_serving,
              });
              handleAddFood();
              setDialogOpen(false);
            } else {
              handleAddFood();
              setDialogOpen(false); // Dialog schließen
            }
          } else {
            setSnackbarMessage('Kein Produkt ausgewählt.');
          }
        }}
      />

      {/* Dialog für "Eigene Lebensmittel" */}
      <FoodDialogOwnAdded
        open={isOwnDialogOpen}
        onClose={() => setOwnDialogOpen(false)}
        item={selectedItem}
        quantity={quantity}
        setQuantity={setQuantity}
        onAdd={(calculatedCalories) => {
          if (selectedItem) {
            console.log('Berechnete Kalorien:', calculatedCalories);
            handleAddFood(calculatedCalories, true); // true für eigenes Lebensmittel
            setOwnDialogOpen(false);
          } else {
            setSnackbarMessage('Kein Produkt ausgewählt.');
          }
        }}
      />

      {/* Dialog für "Bearbeiten und Löschen eigener Lebensmittel" */}
      <EditFoodDialogOwnAdded
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        item={selectedItem}
        onUpdate={(id, newName, newKcal, newMealtime) => handleUpdateFood(id, newName, newKcal, newMealtime)}
        onDelete={(id) => handleDeleteFood(id)}
      />

      {/* Dialog zum hinzufügen eigener Lebensmittel */}
      <AddFoodDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onFoodAdded={() => fetchOwnAddedItems()} // Nach Hinzufügen die Tabelle aktualisieren
      />

    </Container>
  );
};

export default LunchPage;