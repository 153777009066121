import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://nobnohungry.de/api/users/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });          

      if (response.ok) {
        const data = await response.json();
        
        // Speichere den JWT-Token im localStorage
        localStorage.setItem('token', data.token);

        // Weiterleitung nach erfolgreichem Login
        navigate('/dashboard');
      } else {
        setErrorMessage(
          'Mit dieser Kombination aus E-Mail und Passwort konnte kein Login durchgeführt werden. Bitte prüfe beide Angaben und versuche es erneut.'
        );
      }
    } catch (err) {
      console.error('Fehler beim Login:', err);
      setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <TextField
          label="E-Mail Adresse"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Passwort"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button
          variant="text"
          fullWidth
          sx={{ mt: 1 }}
          onClick={() => navigate('/register')}
        >
          Registrieren
        </Button>
      </Box>
    </Container>
  );
};

export default Login;