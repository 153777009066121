import React, { useState, useEffect } from 'react';
import {
  Snackbar,
  IconButton,
  TextField,
  Button,
  Container,
  Typography,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Profile = () => {
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [gender, setGender] = useState('');
  const [calories, setCalories] = useState(null);
  const [activity_level, setActivity] = useState(1.2);
  const [goal, setGoal] = useState('gewicht_halten');
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [settingsExist, setSettingsExist] = useState(false); // Zustand für vorhandene Einstellungen

  const token = localStorage.getItem('token');

  // Funktion, um den Kalorienbedarf zu berechnen
  const calculateCalories = (age, height, weight, gender, activity_level, goal) => {
    if (!age || !height || !weight || !gender || !activity_level || !goal) return null;

    // Grundumsatz berechnen
    const bmr =
      gender === 'male'
        ? 88.36 + 13.4 * weight + 4.8 * height - 5.7 * age
        : 447.6 + 9.2 * weight + 3.1 * height - 4.3 * age;

    // Gesamtumsatz berechnen
    const tdee = bmr * activity_level;

    // Ziel berücksichtigen
    let adjustedCalories = tdee;
    if (goal === 'stark_abnehmen') adjustedCalories = Math.max(tdee * 0.7, 1200);
    if (goal === 'moderat_abnehmen') adjustedCalories = Math.max(tdee * 0.85, 1200);

    return Math.round(adjustedCalories);
  };

  // Funktion, um vorhandene Daten zu laden
  const fetchUserSettings = async () => {
    if (!token) return;

    try {
      const response = await fetch('/api/users/get-settings', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Werte in die Zustände setzen
        setSettingsExist(true);
        setAge(data.age || '');
        setHeight(data.height || '');
        setWeight(data.weight || '');
        setGender(data.gender || '');
        setActivity(data.activity_level || 1.2);
        setGoal(data.goal || 'gewicht_halten');
        setCalories(data.calories || null);
      } else if (response.status === 404) {
        setSettingsExist(false); // Keine Einstellungen gefunden
      }
    } catch (error) {
      console.error('Fehler:', error);
    } finally {
      setIsLoading(false); // Ladezustand beenden
    }
  };

  // Funktion, um die Daten zu speichern
  const saveUserSettings = async () => {
    const newCalories = calculateCalories(age, height, weight, gender, activity_level, goal);
    if (!newCalories) {
      console.error('Alle Felder müssen ausgefüllt sein!');
      return;
    }

    try {
      const endpoint = settingsExist
        ? '/api/users/update-settings' // Update wenn Daten existieren
        : '/api/users/insert-settings'; // Insert wenn keine Daten vorhanden

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          age,
          height,
          weight,
          gender,
          activity_level,
          goal,
          calories: newCalories,
        }),
      });

      if (response.ok) {
        setCalories(newCalories);
        setOpenSnackbar(true);
        console.log(
          settingsExist
            ? 'Einstellungen erfolgreich aktualisiert.'
            : 'Einstellungen erfolgreich hinzugefügt.'
        );
      } else {
        console.error('Fehler beim Speichern der Benutzereinstellungen.');
      }
    } catch (error) {
      console.error('Fehler:', error);
    }
  };

  useEffect(() => {
    fetchUserSettings();
  }, []);

  if (isLoading) {
    return <Typography>Lade Benutzereinstellungen...</Typography>;
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100vh',
        overflowY: 'auto',
        paddingBottom: '64px',
      }}
    >
      <Box mt={4} mb={2}>
        <Typography variant="h4" align="center">
          Profil verwalten
        </Typography>
      </Box>
      <form>
        <TextField
          fullWidth
          label="Alter"
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Größe (cm)"
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Gewicht (kg)"
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="gender-label">Geschlecht</InputLabel>
          <Select
            label="Geschlecht" // Das Label direkt setzen
            labelId="gender-label"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="male">Männlich</MenuItem>
            <MenuItem value="female">Weiblich</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="activity-label">Aktivität</InputLabel>
          <Select
            label="Aktivität" // Das Label direkt setzen
            labelId="activity-label"
            value={activity_level}
            onChange={(e) => setActivity(e.target.value)}
          >
            <MenuItem value={1.2}>Sehr wenig aktiv</MenuItem>
            <MenuItem value={1.375}>Leicht aktiv</MenuItem>
            <MenuItem value={1.55}>Mäßig aktiv</MenuItem>
            <MenuItem value={1.725}>Sehr aktiv</MenuItem>
            <MenuItem value={1.9}>Extrem aktiv</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="goal-label">Ziel</InputLabel>
          <Select
            label="Ziel" // Das Label direkt setzen
            labelId="goal-label"
            value={goal}
            onChange={(e) => setGoal(e.target.value)}
          >
            <MenuItem value="stark_abnehmen">Stark abnehmen</MenuItem>
            <MenuItem value="moderat_abnehmen">Moderat abnehmen</MenuItem>
            <MenuItem value="gewicht_halten">Gewicht halten</MenuItem>
          </Select>
        </FormControl>

        {calories && (
          <Box mt={2}>
            <Typography variant="h6" align="center">
              Berechneter Kalorienbedarf: {calories} kcal
            </Typography>
          </Box>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={saveUserSettings}
          sx={{ mt: 2 }}
        >
          Speichern
        </Button>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Einstellungen gespeichert"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default Profile;