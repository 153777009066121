import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';

const FoodDialog = ({ open, onClose, item, quantity, setQuantity, unit, setUnit, totalCalories, onAdd }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{item?.product_name}</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={2} mt={1}>
          <TextField
            label="Menge"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel shrink={true} sx={{ backgroundColor: 'white', px: 1 }}>
              Einheit
            </InputLabel>
            <Select
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              fullWidth
            >
              <MenuItem value="100">100 {item?.unit}</MenuItem>
              <MenuItem value={item?.unit}>{item?.unit}</MenuItem>
              {item?.kcal_serving && (
                <MenuItem value="portion">Portion</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Typography mt={2} textAlign="center">
          Gesamtkalorien: {Math.round(totalCalories)} kcal
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAdd}
          disabled={!quantity || quantity <= 0} // Button deaktiviert, wenn Menge ungültig ist
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoodDialog;
